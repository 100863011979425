<template>
  <header class="layout_header new_page_header">
    <div class="hinner disflex">
      <back-button v-if="!to" />
      <router-link v-else class="back" :to="to" />

      <h2 class="new_title brand_name">{{ title }}</h2>
      <basket-button />
    </div>
    <slot />
  </header>
</template>

<script>
import BackButton from '../../../ui/Buttons/BackButton.vue';
import BasketButton from './components/BasketButton/BasketButton.vue';
export default {
  components: { BackButton, BasketButton },
  props: ['title', 'to'],
};
</script>

<style></style>
