<template>
  <product-image v-if="pickItems" :item="item" class="product-item">
    <slot name="inImageUp" />
    <book-mark-button
      v-if="bookMark"
      :pickItemId="item.cit_id"
      :value="isPick"
      style="bottom: 0"
      type="i"
      @click="handleClickBookMark"
      @update="handleUpdateBookMark"
    />
    <slot name="inImageDown" />
    <template slot="outSide">
      <slot>
        <div v-if="item.is_sale === 'y'" slot="outSide" class="prod_text">
          <h6 class="prod_title">{{ item.den_name }}</h6>
          <h6 class="prod_price">
            <em class="discount_price">{{ item.cit_price.numberFormat() }}</em>
            <span class="discount">{{ discount }}% </span>
            <span>{{ item.cit_sale_price.numberFormat() }}</span>
          </h6>
        </div>
        <div v-else class="prod_text">
          <h6 class="prod_title">{{ item.den_name }}</h6>
          <h6 class="prod_price">
            <span>{{ item.cit_price.numberFormat() }}</span>
          </h6>
          <!-- 마크가 있고 없고 해야함 -->
          <!-- <p class="tag_wrap">
            <span class="tag gray">SILD배송</span>
          </p> -->
        </div>
      </slot>
    </template>
  </product-image>
</template>

<script>
import { ref } from 'vue-demi';
import BookMarkButton from '../Buttons/BookMarkButton.vue';
import ProductImage from '../ProductImage/ProductImage.vue';
import usePick from '@/composables/user/usePick';

export default {
  components: { BookMarkButton, ProductImage },
  props: ['item', 'bookMark', 'data-id'],
  setup(props, context) {
    const price = props.item.cit_price;
    const sale = props.item.cit_sale_price;

    const { getUserPick } = usePick();
    const pickItems = props.pick_check === 'y' ? ref(true) : ref(null);
    // 이밑에 있는게 트루여야 됨
    const isPick = ref(false);

    const loadPickItems = async () => {
      const items = await getUserPick('items');
      isPick.value =
        items.findIndex(item => item.cit_id === props.item.cit_id) > -1;
      pickItems.value = items;
    };

    loadPickItems();

    return {
      isPick,
      pickItems,
      discount: 100 - Math.floor((sale / price) * 100),
      handleClickBookMark(e) {
        e.preventDefault();
      },
      handleUpdateBookMark(checked) {
        // console.log('bookMark');
        // console.log(checked);
        context.emit('update:bookmark', checked, props.item);
      },
    };
  },
};
</script>

<style>
.prod_img {
  overflow: hidden;
}
</style>
