<template>
  <div>
    <img :src="url" class="image-bg-src" @click="$emit('click')" />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      default: '',
    },
    baseURL: {
      default: process.env.VUE_APP_FILE_URL,
    },
  },

  setup({ src, baseURL }) {
    let url = '';

    if (src) {
      url = src.substr(0, 4) === 'http' ? src : baseURL + src;
    }

    const IMGre = document.querySelectorAll('.image-bg-src');
    IMGre.forEach(el => {
      el.addEventListener('load', () => {
        const img_W = el.naturalWidth;
        const img_H = el.naturalHeight;

        if (img_H / img_W > 1.3) {
          el.style.objectPosition = 'center top';
        } else if (img_H / img_W <= 1.3) {
          el.style.objectPosition = 'center center';
        } else {
          el.style.objectPosition = 'center top';
        }
      });
    });

    return { url };
  },
};
</script>

<style scoped>
.image-bg-src {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}
.sub_banner .prod_img,
.profile_img {
  overflow: hidden;
}
.sub_banner .prod_img img,
.profile_img img {
  object-position: center center !important;
  overflow: hidden;
  position: absolute;
}
</style>
