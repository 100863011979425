<template>
  <div class="wrap recently">
    <back-title-basket :title="$t('Mypage.RecentlyProduct')" />
    <div :class="`${isLoading ? '' : 'off'} layout_body`">
      <div style="display: flex; justify-content: space-between">
        <p class="sub-title">
          {{ $t('Mypage.RecentlyProduct') }} <strong>{{ total }}</strong>
        </p>
        <!-- <button type="button" class="sub-title" @click="clear">전체삭제</button> -->
      </div>
      <div class="noswiper_prod p0">
        <ul class="product-list">
          <template v-if="items.length > 0">
            <li v-for="item in items" :key="item.cit_id">
              <product-item :item="item" :bookMark="true">
                <div
                  slot="inImageUp"
                  class="delete"
                  :data-id="item.cit_id"
                  @click="handleClickDelete"
                />
              </product-item>
            </li>
          </template>
          <template v-else>
            <p class="middle_txt" style="padding-bottom: 43px">
              {{ $t('RecentlyProduct.NoneRecently') }}
            </p>
          </template>
        </ul>
      </div>
    </div>
    <div v-if="!isLoading" class="loading_wrap">
      <div class="loading_content">
        <img src="../../assets/images/design/loading.gif" alt="loading" />
      </div>
    </div>
    <sild-footer />
    <infinite-loading
      v-if="hasMore"
      :identifier="infiniteId"
      @infinite="loadDatas"
    >
      <span slot="no-more"></span>
    </infinite-loading>
  </div>
</template>

<script>
import ProductAPI from '../../apis/ProductAPI';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
// import useUserInfo from '../../composables/user/useUserInfo';
import InfiniteLoading from 'vue-infinite-loading';
import { computed, reactive, toRefs } from 'vue-demi';
import ProductItem from '../../components/ui/ProductItem/ProductItem.vue';
import useConfirm from '../../composables/dialogs/useConfirm';

export default {
  components: { SildFooter, BackTitleBasket, InfiniteLoading, ProductItem },
  setup(_, context) {
    // const { info } = useUserInfo();
    const { showConfirm } = useConfirm();

    const recentItems = JSON.parse(
      localStorage.getItem('recent-items') ?? '[]'
    );
    console.log(recentItems, 'recentItems');
    const state = reactive({
      items: [],
      hasMore: recentItems.length > 0,
      infiniteId: Date.now(),
      isLoading: false,
    });
    const total = computed(() => {
      return state.items.length;
    });

    const params = {
      page: 0,
      limit: 30,
    };

    const createItems = (ids, items) => {
      return ids.map((id) => items.find((item) => item.cit_id == id));
    };

    const loadDatas = async (attr) => {
      const ids = getPageIds();
      const mem_id = localStorage.getItem('mem_id') ?? 0;

      if (ids === 0) {
        attr?.complete();
        state.hasMore = false;
      }

      const { data } = await ProductAPI.getItemByProductIds({
        product_ids: ids,
        mem_id: mem_id,
      });
      console.log(ids);
      state.items = [...state.items, ...createItems(ids, data.result_data)];
      state.items = state.items.filter((item) => {
        return item !== undefined;
      });

      if (ids.length < params.limit) {
        attr?.complete();
        state.hasMore = false;
      } else {
        params.page++;
        attr?.loaded();
      }
      state.isLoading = true;
    };
    if (state.items.length === 0) {
      state.isLoading = true;
    }

    const getPageIds = () => {
      const { page, limit } = params;
      const start = page * limit;

      return recentItems.slice(start, start + limit);
    };
    console.log(state.items, 'state.items');

    return {
      ...toRefs(state),
      total,
      loadDatas,
      clear() {
        showConfirm(context.root.$t('RecentlyProduct.AllDelete'), () => {
          localStorage.setItem('recent-items', JSON.stringify([]));
        });
      },
      handleClickDelete(e) {
        e.preventDefault();

        const id = e.target.getAttribute('data-id');

        showConfirm(context.root.$t('RecentlyProduct.PartDelete'), () => {
          const idx = recentItems.indexOf(Number(id));

          recentItems.splice(idx, 1);

          state.items = state.items.filter((item) => item.cit_id != id);

          localStorage.setItem('recent-items', JSON.stringify(recentItems));
        });
      },
    };
  },
};
</script>

<style scoped>
.recently ul li {
  width: 30%;
  display: inline-block;
  margin-left: 2.5%;
  vertical-align: top;
}
.sub-title {
  padding: 14px;
  font-size: 12px;
}

.sub-title strong {
  font-size: 12px;
}
.delete {
  width: 16px;
  height: 16px;
  background: url('~@/assets/images/design/sakje.svg') no-repeat left center;
  background-size: 16px 16px;
  margin-right: 10px;
  position: absolute;
  top: 2px;
  right: 0px;
}

.main .layout_body {
  padding-top: 0;
}

.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}
.main .hinner.bg {
  border-bottom: 1px solid #202020;
}
.loading_content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: transparent;
  z-index: 999;
}
.loading_content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50em;
}
@media (min-width: 1240px) {
  .loading_content {
    max-width: 360px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.layout_body.off {
  opacity: 0;
}
</style>
