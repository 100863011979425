<template>
  <router-link class="prod_box product-image" :to="`/product/${item.cit_id}`">
    <!-- <template v-if="item.is_gubun === '일반연동'">
      <image-bg-src :baseURL="item.cit_file_1" class="prod_img">
        <slot />
      </image-bg-src>
    </template> -->

    <image-bg-src :src="item.cit_file_1" class="prod_img">
      <slot />
    </image-bg-src>

    <slot name="outSide" />
  </router-link>
</template>

<script>
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';

export default {
  components: { ImageBgSrc },
  props: ['item'],
  setup() {
    return {};
  },
};
</script>

<style></style>
